import { Atom } from ":mods";
import { For, createSignal, splitProps } from "solid-js";
import { ModulesTasksProps, ShowSubmissionStudentViewProps } from "../../../models";

type OnViewTaskOrQuestion = Pick<ShowSubmissionStudentViewProps, "type" | "arr">;
type ModulesSectionProps = Record<string, any> & {
  events: {
    onViewTaskOrQuestion: (props: OnViewTaskOrQuestion) => void;
  };
};
export function ModulesSection(props: ModulesSectionProps) {
  const [local, other] = splitProps(props, ["modules_tasks", "modules_questions", "events"]);
  const [questionsOpen, setQuestionsOpen] = createSignal(false);
  const handleQuestAccordionClicks = () => {
    setQuestionsOpen(questionsOpen() ? false : true);
  };

  function onViewTaskOrQuestion(props: OnViewTaskOrQuestion) {
    local.events.onViewTaskOrQuestion?.(props);
  }
  return (
    <section id="tasks-section">
      <p class="text-14px font-700 mb-20px">My Exhibition - Foundation Level</p>
      <For each={local.modules_tasks}>
        {(module) => {
          const [moduleOpen, setModuleOpen] = createSignal(false);
          const handleAccordionClicks = () => {
            setModuleOpen(moduleOpen() ? false : true);
          };
          return (
            <Atom.Foldable.Accordion
              arrowMode="end"
              onclick={handleAccordionClicks}
              iconClass={`w-24px h-24px  icon-local:down-arrow ${moduleOpen() && "!rotate-180"}`}
              title={
                <section class="flex flex-row justify-start items-center w-full font-700 select-none">
                  <span>{module.module_name} </span>
                </section>
              }
              class="border-2 border-solid border#p px-2 py-20px mb-20px"
            >
              <div class="flex flex-col mt-2 gap-10px">
                <div class="flex flexrow w-full h-fit justify-between">
                  <span class="mb-20px">Tasks {module.student_tasks.length}</span>
                </div>
                <For each={module.student_tasks}>
                  {(task, index) => {
                    return (
                      <div class="flex flexrow w-full h-fit justify-between">
                        <span>{task.name}</span>
                        <button
                          class="cursor-pointer"
                          onclick={() => onViewTaskOrQuestion({ type: "tasks", arr: module.student_tasks })}
                        >
                          View
                        </button>
                      </div>
                    );
                  }}
                </For>
              </div>
            </Atom.Foldable.Accordion>
          );
        }}
      </For>

      <Atom.Foldable.Accordion
        arrowMode="end"
        iconClass={`w-24px h-24px  icon-local:down-arrow ${questionsOpen() && "!rotate-180"}`}
        onclick={handleQuestAccordionClicks}
        title={
          <section class="flex flex-row justify-start items-center w-full font-700 select-none">
            <span>Questions (Foundation Course) </span>
          </section>
        }
        class="border-2 border-solid border#p px-2 py-20px mb-20px"
      >
        <div class="flex flex-col mt-2 gap-22px">
          <div class="flex flexrow w-full h-fit justify-between"></div>
          <For each={local.modules_questions}>
            {(module, index) => {
              return (
                <div class="flex flex-col w-full h-fit gap-14px">
                  <p class="text-14px font-700">{module.module_name}</p>
                  <For each={module.student_questions}>
                    {(question) => {
                      console.log("question :: ", question);

                      return (
                        <div class="flex flexrow w-full h-fit justify-between">
                          <div class="flex gap-10px">
                            <input type="checkbox" readOnly class={"w-25px h-25px cursor-pointer"} />
                            <p class="w-fit pr-10px">{question.name}</p>
                          </div>
                          <button
                            class="cursor-pointer"
                            onclick={() => onViewTaskOrQuestion({ type: "question", arr: module.student_questions })}
                          >
                            View
                          </button>
                        </div>
                      );
                    }}
                  </For>
                </div>
              );
            }}
          </For>
        </div>
      </Atom.Foldable.Accordion>
    </section>
  );
}
